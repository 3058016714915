import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a1861800ba0708ce661d1fc4990b433e@o448786.ingest.us.sentry.io/4508322013118464",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});